import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TokenService } from '@core/services/token.service';
import { UserRightsService } from '@core/services/user-rights.service';
import { SpinnerComponent } from './modules/spinner/spinner.component';
import { ToastComponent } from './modules/toasts/toast-component/toast.component';

@UntilDestroy()
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, SpinnerComponent, ToastComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  public title = 'dms-front';

  constructor(
    private readonly tokenService: TokenService,
    private userRightsService: UserRightsService,
  ) {}

  public ngOnInit(): void {
    this.initSubscriptions();
  }

  private initSubscriptions(): void {
    this.tokenService.isAuthenticated$.pipe(untilDestroyed(this)).subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.getUserRights();
      }
    });
  }

  private getUserRights(): void {
    this.userRightsService
      .getUserRights()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {},
        () => {},
      );
  }
}
