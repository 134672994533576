import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastService } from '@core/services/tost.service';
import { ToastType } from '@core/enums/toast-type.enum';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly toastService: ToastService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((res) => {
        if (
          res instanceof HttpResponse
          && res.body.errors?.length
          && res.body.errors[0].originalError.statusCode !== HttpStatusCode.Unauthorized
          && (request.body.query.startsWith('query ') || request.body.query.toLowerCase().startsWith('mutation archive'))
        ) {
          this.toastService.showToast(res.body.errors[0].message, ToastType.error);
        }
      }),
    );
  }
}
