import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '@core/services/loader.service';
import { environment } from '@environments/environment';
import { imageFileRegex } from '@app/modules/login/regexp.helper';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loader: LoaderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.apiUrl) && !req.url.match(imageFileRegex)) {
      this.loader.showLoader();
    }

    return next.handle(req).pipe(
      finalize(() => {
        if (req.url.startsWith(environment.apiUrl) && !req.url.match(imageFileRegex)) {
          this.loader.hideLoader();
        }
      }),
    );
  }
}
